import React from "react";
import { Link } from "gatsby"

import { GetURL } from "./generate-link-query";


const GenerateLink = (props, { children }) => {
	let url = ''
	if (props.link) {
		url = GetURL(props.link.id)
		if (url == "home") {
			url = ''
		}
		
	}
	return (
		<React.Fragment>
			{url.indexOf("http://") == 0 || url.indexOf("https://") == 0 ?
				<a href={`${url}`} target="_blank" className={props.class}>{props.children}</a>
				: <Link to={`/${url}/`} className={props.class}>{props.children}</Link>
			}
		</React.Fragment>
	)
}

export default GenerateLink;
