import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import axios from "axios";

export const GetURL = (id) => {
    const data = useStaticQuery(graphql`
    query {
		glstrapi {
            menus {
              slug
              link_type
              external_link
              parent {
                link_type
                external_link
                slug
                parent {
                  external_link
                  slug
                  link_type
                  parent {
                    slug
                    link_type
                    external_link
                  }
                }
              }
              _id
              label
            }
        }
    }
  `);

    let PageURL = data.glstrapi?.menus?.filter(item => item._id === id).pop();

    let URL = PageURL?.external_link ? PageURL?.external_link : PageURL?.slug

    if ((PageURL.parent != null) && (PageURL.parent?.parent == null)) {
        URL = PageURL.parent.slug + '/' + URL
    }

    else if ((PageURL.parent != null) && (PageURL.parent?.parent != null)) {
        URL = PageURL.parent.parent.slug + '/' + PageURL.parent.slug + '/' + URL
    }


    // else if ((PageURL.Main_Parent != null) && (PageURL.Sub_Parent != null) && (PageURL.Sub_Parent_Secondary != null)) {
    //     URL = PageURL.Main_Parent.URL + '/' + PageURL.Sub_Parent.URL + '/' + PageURL.Sub_Parent_Secondary.URL + '/' + URL
    // }

    return URL;

}