import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Offcanvas from 'react-bootstrap/Offcanvas'
import { Link } from 'gatsby';
import Menudata from './MenuData';
import Logo from '../../images/Arabian-Estates----Logo.svg'
import LogoBlack from '../../images/arabian-estates-logo-black.svg'

function BurgerMenu(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const data = useStaticQuery(graphql`
        query {
            glstrapi {
                globalModule {
                    Address
                    Email
                    Phone
                    Whatsapp
                }
            }
        }
    `);
    const global = data.glstrapi.globalModule;
    
    // Search Overlay
    // const [showSearchOverlay, setShowSearchOverlay] = useState(false);
    // const handleSearchOverlayClose = () => setShowSearchOverlay(false);
    // const handleSearchOverlayShow = () => setShowSearchOverlay(true);
    // Search Overlay
    return (
        <React.Fragment>
            <div className='burger-nav'>
                <div className="mob-menu" onClick={() => handleShow()}>
                    <span className="menu-bar"></span>
                    <span className="menu-bar"></span>
                    <span className="menu-bar"></span>
                </div>

                {/* <a href="javascript:;" onClick={handleShow}>
                    <i className="icon-burger" onClick={handleShow}></i>
                </a> */}
                <Offcanvas show={show} onHide={handleClose} placement={`end`}>
                    <div className='modal-mobile-header'>
                        <div className='modal-logo'>
                            <Link to="/" className="navbar-brand">
                                <img src={LogoBlack} alt="logo" className="brand-logo new-logo" />
                            </Link>
                        </div>
                        <div className='header-icons'>
                            <a href={`tel:${global.Phone}`}><i className="icon-mobile-phone"></i></a>
                            <a href={`https://wa.me/${global.Whatsapp}`} target="_blank"><i className="icon-mobile-whatsapp"></i></a>
                        </div>
                    </div>
                    <Offcanvas.Header closeButton>

                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Menudata />
                    </Offcanvas.Body>
                </Offcanvas>
            </div>
        </React.Fragment>
    )
}

export default BurgerMenu
