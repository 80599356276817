import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import LocationSelect from '../LocationSelect/LocationSelect';
import { navigate } from "@reach/router";
import './Tabbing.scss';

const Tabbing = (props) => {
  const tabbingData = props.tabbingData;
  var url_main = typeof window !== 'undefined' ? window.location.pathname : ''
  function tabselect(k) {
    if(k == "list a property")
    {
      navigate('/list-a-property')
    }
  }
  return (
    <Tabs defaultActiveKey={url_main == "/property-services/rent-property/" ? "Rent" : url_main == "/property-services/sell-property" || url_main == "/property-services/property-management/" ? 'Off Plan' : 'Buy'} onSelect={(k) => tabselect(k)}>
      {
        Object.keys(tabbingData).map((data, index)=>{
          return <Tab key={index} eventKey={data} title={data}>
            <LocationSelect landingpage={props?.landingpage} isheader={props?.isheader} tabbingData={tabbingData[data]} title={data}/>
          </Tab>
        })
      }
    </Tabs>
  )
}

export default Tabbing