import { Link } from "gatsby"
import React from 'react'
import parse from "html-react-parser"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from 'react-bootstrap'
import './Footer.scss';
import FooterLogo from "../../images/footer-logo.svg"
import GenerateLink from "../../common/link/generate-link"

const Footer = (props) => {


  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      socialLink {
        footer_menu {
          footer_links {
            label
            menu_item {
              id
            }
          }
          title
        }
      }
      globalModule {
        Address
        Email
        Phone
        Whatsapp
      }
      siteConfig {
        facebook_link
        instagram_link
        linkedin_link
        twitter_link
      }
    }
  }
`);

  const allmenus = data.glstrapi.socialLink.footer_menu;
  const global = data.glstrapi.globalModule;
  const social = data.glstrapi.siteConfig;

  const year = new Date().getFullYear()
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col>
            <div className='footer-wrapper'>
              <div className='footer-menu address-menu'>
                <h5>Contact</h5>
                <address className="address">
                  {parse(global.Address)}
                </address>
                <a className="phone" href={`tel:${props?.isHoliday ? "+971 58 595 4800" : global.Phone}`}>{props?.isHoliday ? "+971 58 595 4800" : global.Phone}</a>
                {props?.isHoliday ? <Link className="email" href={`mailto:booking@arabianholidays.ae`}>booking@arabianholidays.ae</Link> :
                <Link className="email" to={`/contact-arabian-estates/`}>{global.Email}</Link>}
              </div>
              {allmenus && allmenus.map((item, index) => {
                return (
                  <div className='footer-menu explore-menu'>
                    <h5>{item.title}</h5>
                    <ul>
                      {item && item.footer_links.map((links, index) => {
                        return (
                          <li>
                            <GenerateLink class="footer-items" link={links.menu_item}>{links.label}</GenerateLink>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}
              <div className='footer-menu social-menu'>
                <div className='footer-icons'>
                  <ul>
                    {social.facebook_link &&
                    <li>
                      <a href={props?.isHoliday ? "https://www.facebook.com/arabianholidays/" : social.facebook_link} target="_blank" className="social-icon">
                        <i className="icon-facebook"></i>
                      </a>
                    </li>
                    }
                    {(social.twitter_link) && (!props?.isHoliday) &&
                    <li>
                      <a href={social.twitter_link} target="_blank" className="social-icon">
                        <i className="icon-twitter"></i>
                      </a>
                    </li>
                    }
                    {social.instagram_link &&
                    <li>
                      <a href={props?.isHoliday ? "https://www.instagram.com/arabian_holidayhomes" : social.instagram_link} target="_blank" className="social-icon">
                        <i className="icon-insta"></i>
                      </a>
                    </li>
                    }
                    {social.linkedin_link && (!props?.isHoliday) &&
                    <li>
                      <a href={social.linkedin_link} target="_blank" className="social-icon">
                        <i className="icon-linkedin"></i>
                      </a>
                    </li>
                    }
                  </ul>
                </div>
                <div className="privacy-policy">
                  <span className="copyright-text">© {year} {props?.isHoliday ? "Arabian Holidays" : "Arabian Estates"}. All Rights Reserved</span>
                  <div className='footer-logo'>
                    <span className="site-copyright">Site by</span>
                    <a href="https://starberry.tv/" target="_blank" className="logo-wrapper">
                      <img src={FooterLogo} alt="footer-img" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer;