import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby"
// import { Link } from "gatsby";
import $ from "jquery";
import GenerateLink from "../../common/link/generate-link"
// import { GetURL } from "../../common/link/generate-link-query"
import './Burger.scss';



const Menudata = (props) => {

    const data = useStaticQuery(graphql`
  query {
    glstrapi {
        burgerMenus(sort: "Sort:asc") {
          label
          menu {
            slug
            id
          }
          add_menu {
            label
            menu {
              id
              slug
            }
          }
        }
    }
  }
`);

    const allmenus = data.glstrapi.burgerMenus;


    useEffect(() => {
        $('.navigation li a').click(function () {
            $(window).scrollTop(0);
        })
        $(".navigation .menu-accordian .submenu-list").each(function (i, el) {
            // Remove white space
            var html = $(el).html().replace(/\s+/, "");
            // Check if element is :empty or length of html is zero
            if ($(el).is(":empty") || html.length === 0) {
                $(el).remove();
            } else {
                $(el).parent().addClass('has-submenu');
            }
        });

        $(".card-header.has-submenu .firstleveltrigger").unbind().on("click", function (e) {
            var checkactive = $(this).parent().attr('class');
            if (checkactive.indexOf('active') !== -1) {
                $(".card-header.has-submenu").removeClass('active');
                $('.submenu-list').stop(true, true).slideUp();
                $('.has-submenu .firstleveltrigger i').removeClass('icon-arrow-up');
                $('.has-submenu .firstleveltrigger i').addClass('icon-arrow-down');
            } else {
                $(".card-header.has-submenu").removeClass('active');
                $('.submenu-list').slideUp();
                $('.has-submenu .firstleveltrigger i').removeClass('icon-arrow-up');
                $('.has-submenu .firstleveltrigger i').addClass('icon-arrow-down');
                $(this).parent().addClass("active");
                $(this).parent().find('.submenu-list').stop(true, true).slideDown();
                $(this).parent().find('.firstleveltrigger i').removeClass('icon-arrow-down');
                $(this).parent().find('.firstleveltrigger i').addClass('icon-arrow-up');
            }
        });
    }, []);

    // var IfExternal_URL = /^((http|https|ftp):\/\/)/;

    return (
        <React.Fragment>
            <div className="navigation">
                <div className="menu-accordian">
                    {allmenus && allmenus.map((item, index) => {
                        return (
                            <li className={"card-header card-header-parent"}>
                                {item.menu ?
                                <GenerateLink link={item.menu} class="firstleveltrigger">{item.label}</GenerateLink> : <div className="firstleveltrigger">{item.label}</div> }
                                <div className="firstleveltrigger">
                                    {item.add_menu && item.add_menu.length > 0 &&
                                        <i className="icon-arrow-down"></i>
                                    }
                                </div>
                                <ul className="submenu-list">
                                    {item.add_menu && item.add_menu.length > 0 && item.add_menu.map((submenu) => {
                                        return <li className="card-header card-header-child secondlevel-menu-item">
                                        <GenerateLink link={submenu.menu}>{submenu.label}</GenerateLink>
                                            
                                        </li>
                                    })}
                                </ul>

                            </li>
                        )
                    })}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Menudata;
