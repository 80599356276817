import React from "react"
import Header from "../components/Header/Header";
import Footer from "./Footer/Footer";
const Layout = (props) => {

  return (
    <>
      <Header isHoliday={props?.isHoliday} />
      {props.children}
      <Footer isHoliday={props?.isHoliday} />
    </>

  );
};


export default Layout;
