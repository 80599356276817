import React from "react";

const AreaList = ({ areas, removeArea, className = '' }) => {
    if (!areas || areas.length < 1)
        return <></>

    function removeAreaFun(e, area) {
        e.preventDefault();
        removeArea(area)
    }
    function removeAreaAllFun(e, area) {
        e.preventDefault();
        removeArea('', true)
    }

    return (
        <>
            {/*<Container>
            <Row>
                <Col>*/}

            <div className={`search-head area-list ${className}`}>
                <div className="property-sale">
                    <div className="sort-tags">
                        {areas.map((item, key) => {
                            return (
                                // <li>
                                    <div className="selected-sort">
                                        <span className="selected-text">
                                            {item.name}
                                        </span>
                                        <button className="cross-btn" onClick={(e) => removeAreaFun(e, item.slug)}><i className="icon-cross"></i></button>
                                    </div>
                                // </li>
                            )
                        })}
                        {/* <li> */}
                            <button className="clear-all" onClick={(e) => removeAreaAllFun(e, '')}>Clear All</button>
                        {/* </li> */}
                    </div>
                </div>
            </div>
            {/*</Col>
            </Row>
        </Container> */}
        </>
    )
}

export default AreaList;